import PartnerItem from './PartnerItem/PartnerItem.vue'

export default {
  props: {
    partners: Array
  },
  components: {
    PartnerItem
  }
}
